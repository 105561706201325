import CardTournament from './TournamentList/CardTournament';
import { Box, Paper, Stack, Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import { ScrollContext } from 'context/ScrollContext';
import { Text } from 'design/Text';
import React, { useEffect, createRef, useLayoutEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { useAppThunkDispatch } from 'redux/store';
import { resetTournament } from 'redux/tournament/TournamentReducer';
import { fetchTournamentList } from 'redux/tournament/TournamentServices';
import { IconSource } from 'modules/assetpath';
import { useTranslation } from 'react-i18next';
import { NormalText } from 'components/styled/text.styled';
import { Stack as CustomStack } from 'components/styled/layout.styled';

const FilterTabs = styled(Box)(({ theme }: any) => ({
  padding: '16px 16px 0',
  '.MuiPaper-root': {
    background: theme.palette.primaryOrange700,
    display: 'inline-block',
    padding: '1px',
  },
  p: {
    padding: '8px 12px',
    background: '#1A1817',
    borderRadius: '4px',
    display: 'inline-block',
    fontFamily: theme.fontFamily.lexendDeca,
  },
}));

export const SkeletonGroup = () => (
  <Box>
    <Skeleton
      variant="rectangular"
      animation="wave"
      height={200}
      style={{ marginBottom: '10px' }}
    />
    <Skeleton animation="wave" height={30} width="80%" />
    <Skeleton animation="wave" height={30} width="60%" />
    <Skeleton animation="wave" height={30} width="40%" />
  </Box>
);

const tryOut = () => {
  const { t } = useTranslation();
  const location = useLocation<any>();
  const { value: scrollValue } = useContext(ScrollContext);
  const dispatch = useAppThunkDispatch();

  const { tournamentList, tournamentListStatus } = useAppSelector(({ tournament }) => ({
    tournamentList: tournament?.tournamentList,
    tournamentListStatus: tournament?.status?.tournamentList,
  }));

  const handleFetchTournamentList = () => {
    dispatch(
      fetchTournamentList({
        pageIndex: 1,
        pageSize: 100,
        typeId: 1,
      }),
    );
  };

  useEffect(() => {
    Promise.all([dispatch(resetTournament()), handleFetchTournamentList()]);
  }, []);

  // multiple tournamentList ref
  const tournamentListRef: any =
    (tournamentList?.items ?? []).length > 0 &&
    Array(tournamentList?.items.length)
      .fill('')
      .map(() => createRef());

  useLayoutEffect(() => {
    if (location && location.state && location.state.scrollTarget && tournamentListRef.length > 0) {
      scrollValue.scrollRef?.current?.scrollTo(0, 0);
      const { y } = tournamentListRef[location.state.scrollTarget].current.getBoundingClientRect();
      const offsetFromHeaderTab = 100 + 20;
      const offsetY = y - offsetFromHeaderTab;
      return scrollValue.scrollRef?.current?.scrollTo(0, offsetY);
    }
  }, [location, tournamentListRef]);

  const handleLoader = () => {
    if (tournamentListStatus == 'failed') {
      return 'none';
    }

    if (tournamentListStatus !== 'succeeded') {
      if (tournamentList && tournamentList.items.length > 0) return 'none';
      return '';
    }
    return 'none';
  };

  const handleContent = () => {
    if (tournamentListStatus !== 'succeeded') {
      if (tournamentList && tournamentList.items.length > 0) return '';
      return 'none';
    }
    return '';
  };

  return (
    <>
    {tournamentList && (tournamentList?.items ?? []).length > 0 && (
      <FilterTabs>
        <Paper>
          <Text variant="subtitle">Mobile Legends: Bang Bang</Text>
        </Paper>
      </FilterTabs>
    )}

      <Stack spacing={3} sx={{ padding: '16px', pb: '60px', display: handleLoader() }}>
        <SkeletonGroup />
        <SkeletonGroup />
        <SkeletonGroup />
      </Stack>

      {tournamentListStatus == "failed" ? (
        <CustomStack
          direction="column"
          align="center"
          margin="70px 0px 0px 0px"
          disableSelectText
          disableDrag
        >
          <img alt="" src={IconSource('mabar-typeface-fullwhite')} style={{ maxWidth: '115px', margin: '0 auto' }}/>
          <NormalText
            fontSize="14px"
            fontWeight="400"
            color="#969393"
            lineHeight="20.44px"
            textAlign="center"
            margin="24px 0px 30px 0px"
          >
            {t('tournament_end')}
          </NormalText>
        </CustomStack>
      ) : (
        <Stack spacing={3} sx={{ padding: '16px', pb: '60px', display: handleContent() }}>
          {tournamentList && (tournamentList?.items ?? []).length > 0 &&
            tournamentList.items.map((item, index) => (
              <Box key={index} ref={tournamentListRef[index]}>
                <CardTournament {...item} index={index} />
              </Box>
            ))}
        </Stack>
      )}
    </>
  );
};

export default tryOut;

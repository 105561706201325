import { Avatar, Box, Stack } from '@mui/material';
import competitionLandingJson from 'data/competitions/competitionLanding.json';
import { SecondaryButton } from 'design/Buttons/SecondaryButton';
import { CompetitionTitle } from 'design/Ribbon/CompetitionTitle';
import { Champion, CandidateProps } from 'design/Section/Champion';
import { Text } from 'design/Text';
import { ImageSource } from 'modules/assetpath';
import React from 'react';
import { Link } from 'react-router-dom';
import { Parallax } from 'react-scroll-parallax';

const CompetitionList = () => {
  const restrutureCandidateData: any = (data: []) => {
    return data.map((data: CandidateProps) => ({
      ...data,
      schoolLogo: `${process.env.PUBLIC_URL}/media/images/sekolah/${data.schoolLogo}.png`,
      teamLogo:
        data.teamLogo || data.teamLogo !== ''
          ? `${process.env.PUBLIC_URL}/media/images/tim/${data.teamLogo}.png`
          : undefined,
    }));
  };

  return (
    <>
      <Box
        sx={{
          backfaceVisibility: 'hidden',
          transformStyle: 'preserve-3d',
          transform: 'translate3d(0,0,0)',
        }}
      >
        <Parallax
          translateY={[0, 25, 'easeInOut']}
          opacity={[1, 0]}
          shouldAlwaysCompleteAnimation={true}
        >
          <Box
            sx={{
              position: 'relative',
              '&:before': {
                content: "''",
                position: 'absolute',
                bottom: 0,
                width: '100%',
                height: '50px',
                background: 'linear-gradient(180deg, transparent 0%, #101216 100%)',
                zIndex: 1,
              },
            }}
          >
            <Champion
              title={competitionLandingJson.champion.title}
              description={<CompetitionTitle label={competitionLandingJson.champion.description} />}
              candidate={restrutureCandidateData(competitionLandingJson.champion.candidate)}
              backgroundImage={ImageSource(competitionLandingJson.champion.backgroundImage, 'png')}
              sx={{
                pb: '60px',
                backgroundPosition: 'center top',
              }}
            />
          </Box>
        </Parallax>
      </Box>

      <Stack
        spacing={1}
        sx={{
          position: 'relative',
          px: '8px',
          mt: '-60px',
          mb: '200px',
          pb: '8px',
          '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '50%',
            left: 0,
            top: '25%',
            filter: 'blur(30px)',
            bgcolor: 'neutral900',
          },
        }}
      >
        {competitionLandingJson.competitionList.map(competition => (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={theme => ({
              position: 'relative',
              width: '100%',
              minHeight: '208px',
              background: `#171616 url(${ImageSource(
                competition.backgroundImage,
                'png',
              )}) no-repeat`,
              backgroundSize: 'cover',
              border: '1px solid #242628',
              borderRadius: theme.borderRadius.md,
            })}
          >
            {competition.logo && (
              <Box
                sx={{
                  position: 'relative',
                  '&:before': {
                    content: "''",
                    position: 'absolute',
                    width: '40%',
                    height: '40%',
                    inset: 0,
                    margin: 'auto',
                    boxShadow: '0px 4.70588px 72px rgba(200, 105, 37)',
                    zIndex: 0,
                  },
                }}
              >
                <Avatar
                  src={ImageSource(competition.logo, 'png')}
                  alt={competition.title}
                  sx={{
                    position: 'relative',
                    width: '100px',
                    height: '100px',
                    img: { objectFit: 'contain' },
                  }}
                  variant="square"
                />
                <img />
              </Box>
            )}
            <Text variant="h3" sx={{ mb: '4px' }}>
              {competition.title}
            </Text>
            <Text variant="subtitle" sx={{ mb: '10px', color: 'neutral400' }}>
              {competition.description}
            </Text>
            <Link to={competition.url} style={{ marginBottom: '24px' }}>
              <SecondaryButton variant="small">Lihat Detail</SecondaryButton>
            </Link>
          </Stack>
        ))}
      </Stack>
    </>
  );
};

export default CompetitionList;

import { Avatar, Box, Stack, useTheme } from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import s0_2022LandingJson from 'data/awards/s0-2022/landing.json';
import s1_2022LandingJson from 'data/awards/s1-2022/landing.json';
import s1_2023LandingJson from 'data/awards/s1-2023/landing.json';
import s3_2024LandingJson from 'data/awards/s3-2024/landing.json';
import { CardAward } from 'design/Cards/CardAward';
import { CardAwardList } from 'design/Cards/CardAwardList';
import { SelectInput, OptionType } from 'design/Forms/Select';
import { CustomTabs, TabItem } from 'design/Tabs';
import { CompetitionStatus } from 'design/Tag/CompetitionStatus';
import { Text } from 'design/Text';
import { ImageSource } from 'modules/assetpath';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const seasonData: OptionType[] = [
  { name: 'S3 2024', value: 's3-2024' },
  { name: 'S1 2023', value: 's1-2023' },
  { name: 'S1 2022', value: 's1-2022' },
  { name: 'S0 2022', value: 's0-2022' },
];
const seasonDataSlug: String[] = seasonData.map(sch => {
  return sch.value;
});

const AwardsLanding = () => {
  const history = useHistory();
  const location = useLocation<any>();
  const [seasonSelected, setSeasonSelected] = useState<any>();

  useEffect(() => {
    var slug = location.search?.replace('?', '');
    if (seasonDataSlug.includes(slug)) {
      setSeasonSelected(slug);
      history.push({ search: slug });
    } else {
      setSeasonSelected(seasonData[0].value);
      history.push({ search: seasonData[0].value });
    }
  }, [location.search]);

  const [awardsContentData, setAwardsContentData] = useState<any>();

  const seasonFilter = (seasonSelected: string) => {
    switch (seasonSelected) {
      case 's3-2024':
        return s3_2024LandingJson;
      case 's1-2023':
        return s1_2023LandingJson;
      case 's1-2022':
        return s1_2022LandingJson;
      case 's0-2022':
        return s0_2022LandingJson;
      default:
        return undefined;
    }
  };

  useEffect(() => {
    setAwardsContentData(seasonFilter(seasonSelected));
  }, [seasonSelected]);

  const handleSeasonSelect = (value: any) => {
    if (location.search?.replace('?', '') !== value) return history.push({ search: value });
    return null;
  };

  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const handleStepChange = (step: React.SetStateAction<number>) => {
    setActiveStep(step);
  };

  const awardsSx = (type: string) => {
    switch (type) {
      case 'crown':
        return {
          width: '100px',
          height: '100px',
          top: '40px',
          boxShadow: '0px 1.66667px 6.66667px rgba(0, 0, 0, 0.32)',
          borderRadius: '6.66667px',
        };
      default:
        return { width: '106px', height: '106px' };
    }
  };

  return (
    <>
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Avatar
          variant="square"
          src={ImageSource('bg_awards_landing_behind_slider', 'png')}
          sx={{
            height: '484px',
            width: '100%',
            zIndex: '-1',
            position: 'absolute',
            top: '-92px',
            left: '0',
            '.MuiAvatar-img': {
              objectFit: 'fill',
            },
          }}
        />
        <Box
          sx={{
            padding: '16px',
          }}
        >
          <SelectInput
            label="Season"
            defaultValue={seasonSelected}
            options={seasonData}
            onItemChange={value => handleSeasonSelect(value)}
            sx={{ marginBottom: '12px' }}
          />
          <AutoPlaySwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {awardsContentData?.carrousel.map((carrousel: any) => (
              <CardAward
                image={`${process.env.PUBLIC_URL}` + carrousel.image}
                imageSx={awardsSx(carrousel.imageSx)}
                label={
                  <CompetitionStatus
                    variant={carrousel.label.variant}
                    label={carrousel.label.label}
                  />
                }
                title={carrousel.title}
                description={carrousel.description}
                isChampion={carrousel.isChampion}
                isCrown={carrousel.isCrown}
              />
            ))}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={awardsContentData?.carrousel ? awardsContentData.carrousel.length : 0}
            position="static"
            activeStep={activeStep}
            nextButton={''}
            backButton={''}
            sx={{
              backgroundColor: 'transparent',
              justifyContent: 'center',
              paddingTop: '11px',
              ' .MuiMobileStepper-dots': {
                alignItems: 'center',
                ' .MuiMobileStepper-dot': {
                  backgroundColor: '#4E5156',
                  width: '4px',
                  height: '4px',
                },
                ' .MuiMobileStepper-dotActive': {
                  backgroundColor: '#EAEAEC',
                  width: '6px',
                  height: '6px',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                },
              },
            }}
          />

          {awardsContentData && awardsContentData?.awards && (
            <>
              <Text variant="h3" sx={{ m: '32px 0px 20px' }}>
                Tournament Awards
              </Text>

              <Stack direction="row" spacing="15px">
                {awardsContentData.awards.map((awardsButton: any) => (
                  <Link to={awardsButton.url}>
                    <Box
                      sx={{
                        height: '172px',
                        width: '100%',
                        background: 'linear-gradient(180deg, #1A1817 0%, #0A0A0B 100%)',
                        border: '0.5px solid #27282B',
                        boxShadow: '0px 4px 12px rgba(107, 107, 107, 0.12)',
                        borderRadius: '16px',
                        padding: '16px 12px',
                        boxSizing: 'border-box',
                      }}
                    >
                      <Stack sx={{ alignItems: 'center', justifyContent: 'center' }}>
                        <Avatar
                          src={ImageSource(awardsButton.logo, 'png')}
                          sx={{
                            height: '100px',
                            width: '100px',
                            borderRadius: 'initial',
                            img: { objectFit: 'contain' }
                          }}
                        />
                        <Text
                          variant="label"
                          sx={{
                            fontWeight: '400',
                            letterSpacing: '0.2',
                            color: 'neutral400',
                            textAlign: 'center',
                            cursor: 'pointer',
                          }}
                        >
                          {awardsButton.title}
                        </Text>
                      </Stack>
                    </Box>
                  </Link>
                ))}
              </Stack>
            </>
          )}

          {awardsContentData?.otherAwards ? (
            <>
              <Text variant="h3" sx={{ m: '60px 0px 12px' }}>
                Other Awards
              </Text>

              <CustomTabs variant="button" sx={{ mb: '12px' }}>
                <TabItem title="Sekolah">
                  {awardsContentData?.otherAwards.school.map((content: any) => (
                    <CardAwardList
                      awardText={content.award}
                      contentPhoto={
                        content.photo
                          ? `${process.env.PUBLIC_URL}/media/images/${content.photo}.png`
                          : ''
                      }
                      contentText={content.title}
                      contentSubtext={content.subtitle}
                      sx={{ mb: '12px' }}
                    />
                  ))}
                </TabItem>
                <TabItem title="Tim">
                  {awardsContentData?.otherAwards.tim.map((content: any) => (
                    <CardAwardList
                      placeholderType="team"
                      awardText={content.award}
                      contentPhoto={
                        content.photo
                          ? `${process.env.PUBLIC_URL}/media/images/${content.photo}.png`
                          : ''
                      }
                      contentText={content.title}
                      contentSubtext={content.subtitle}
                      sx={{ mb: '12px' }}
                    />
                  ))}
                </TabItem>
                <TabItem title="Individu">
                  {awardsContentData?.otherAwards.individu.map((content: any) => (
                    <CardAwardList
                      avatarIsCircle={true}
                      awardText={content.award}
                      contentPhoto={
                        content.photo
                          ? `${process.env.PUBLIC_URL}/media/images/${content.photo}.png`
                          : ''
                      }
                      contentText={content.title}
                      contentSubtext={content.subtitle}
                      sx={{ mb: '12px' }}
                    />
                  ))}
                </TabItem>
              </CustomTabs>
            </>
          ) : null}
        </Box>
      </Box>
    </>
  );
};

export default AwardsLanding;
